import { FormHelperText, FormErrorMessage, Collapse } from "@chakra-ui/react";

export type FieldFooterProps = {
  error: string | undefined;
  helper: string | undefined;
};

export const FieldFooter = ({ error, helper }: FieldFooterProps) => {
  return (
    <Collapse in={!!error || !!helper}>
      {!error && helper ? <FormHelperText>{helper}</FormHelperText> : <FormErrorMessage>{error || "hi"}</FormErrorMessage>}
    </Collapse>
  );
};
