import { Button, ButtonProps } from "@chakra-ui/react";
import { useIsSubmitting } from "remix-validated-form";

export type ValidatedButtonProps = ButtonProps & {
  formId: string;
  label: string;
};

export function ValidatedButton({ formId, label, ...props }: ValidatedButtonProps) {
  const isSubmitting = useIsSubmitting(formId);
  return (
    <Button type="submit" form={formId} isLoading={isSubmitting} {...props}>
      {label}
    </Button>
  );
}
